



























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CourseCard extends Vue {
  @Prop() previewImg!: string;
  @Prop() code!: string;
  @Prop() progress!: number;
  @Prop({ default: "primary" }) color!: string;
  @Prop() name!: string;
  @Prop() urlCode!: string;
}
